import React from 'react';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";

import Button from '../components/component.button';
import Placeholder from '../assets/images/row-the-future-1.png';

const Info = ({ parallax }) => {
    const data = useStaticQuery(graphql`
        query Info {
            wordpress {
                globalContent(idType: SLUG, id: "why-wintech-racing") {
                    ACFWhyWintechRacingBlockFields {
                        title
                        label
                        images {
                            image {
                                sourceUrl
                                imageFile {
                                    childImageSharp {
                                        fluid(maxWidth: 680) {
                                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                        }
                                    }
                                }
                            }
                        }
                        link {
                            url
                        }
                        reasons {
                            title
                            content
                        }
                    }
                }
            }
        }
    `);

    const info = data.wordpress.globalContent.ACFWhyWintechRacingBlockFields;

    const offsets = [
        "-0.2",
        "-0.14",
        "0",
        "0.42",
        "0.10"
    ];

    const speeds = [
        "-0.05",
        "-0.025",
        "0",
        "0.125",
        "0.05"
    ];

    return (
        <div className="container">
            <div className={parallax ? "c-info c-info--parallax" : "c-info"}>
                <div className="row">
                    <div className="col-md-6 col-lg-5">
                        <div className="c-info__details">
                            <h2 className="c-info__title">{info.title}</h2>

                            <ul className="c-info__details-list">
                                {info.reasons && info.reasons.map((reason, index) => (
                                    <li key={index} className="c-info__details-item">
                                        <h3 className="c-info__subtitle">{reason.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: reason.content }}></div>
                                    </li>
                                ))}
                            </ul>
                            <div className="c-info__button">
                                <Button link to={info.link && info.link.url} text={info.label} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-7 d-none d-md-flex align-items-center">
                        <div className="c-info__images">
                            <img className="u-hidden" src={Placeholder} alt="Placeholder" />
                            {parallax && info.images && info.images.map((image, index) => (
                                <>
                                    {image.image &&
                                        <ParallaxLayer key={index}
                                            className={`c-info__image c-info__image--${index}`}
                                            offset={offsets[index]}
                                            speed={speeds[index]}>
                                            <Img key={index}
                                                fluid={image.image.imageFile.childImageSharp.fluid}
                                                alt={image.altText}
                                            />
                                        </ParallaxLayer>
                                    }
                                </>
                            ))}
                            {!parallax && info.images && info.images.map((image, index) => (
                                <>
                                    {image.image &&
                                        <div
                                            key={index}
                                            className={`c-info__image c-info__image--${index}`}
                                        >
                                            <Img key={index}
                                                fluid={image.image.imageFile.childImageSharp.fluid}
                                                alt={image.altText}
                                            />
                                        </div>
                                    }
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
